import React, { useState } from 'react';
import { Select } from '@naf/input';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { useExternalFetch } from '../../hooks/useExternalFetch';

type Props = {
  url: string;
  searchParams?: { [k: string]: string };
  dataMap: (data: any) => { label: string; value: string }[];
  handleSelect: (item: any) => void;
  error?: boolean;
  placeholder?: string;
  label?: string;
  value?: string;
  disabled?: boolean;
  onChange: (val: string) => void;
};

const ExternalTypeahead = ({
  url,
  dataMap,
  onChange,
  handleSelect,
  error = false,
  placeholder,
  label,
  value,
  searchParams,
  disabled,
}: Props) => {
  const [params, setSearchParams] = useState({ ...searchParams });
  const [initialValue] = useState(value ? { label: value, value } : undefined);
  const skip = !params.sok || params.sok.length < 3;

  const {
    data,
    isLoading,
    error: dataError,
  } = useExternalFetch({
    url,
    searchParams: params,
    skip,
  });

  const setOptions = () => {
    if (!data || skip) return [];
    if (data.adresser.length === 0) {
      return [{ label: 'Ingen treff', value: '', disabled: true }];
    }
    return dataMap(data);
  };

  return (
    <>
      <StyledSelect
        label={label}
        options={setOptions()}
        selected={initialValue || undefined}
        handleSelect={(dataObject: any) => dataObject?.value && handleSelect(dataObject)}
        width="100%"
        error={error}
        placeholder={placeholder}
        maxLines={6}
        value={value}
        filterFunction={(inputValue) => {
          if (inputValue !== value) onChange(inputValue || undefined);
          setSearchParams({ ...searchParams, sok: inputValue });
        }}
        disabled={disabled}
      />
      {!isLoading && dataError && (
        <Text variant={TextVariant.ArticleTextHeader}>
          Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!
        </Text>
      )}
    </>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default ExternalTypeahead;
